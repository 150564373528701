// src/pages/Home.js
import { Link } from 'react-router-dom';
import '../App.css';  // Ensure CSS is imported

export default function Home() {
  return (
    <div className="home">
      <h1>Welcome to Sharmik</h1>
      <p>AI-powered platform for empowering workers and employers.</p>
      <div className="cta-buttons">
        <Link to="/worker-signup">
          <button>Register as Worker</button>
        </Link>
        <Link to="/employer-signup">
          <button>Hire Workers</button>
        </Link>
      </div>

      {/* Add the footer here */}
      <footer>
        <p>© 2024 Sharmik. All rights reserved.</p>
      </footer>
    </div>
  );
}
