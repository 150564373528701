// src/pages/Products.js
import '../App.css';  // Import CSS

export default function Products() {
  return (
    <div className="products-page">
      <h2>Our Products</h2>

      {/* Product 1: Worker Monitoring System */}
      <div className="product">
        <img 
          src={process.env.PUBLIC_URL + '/images/worker-monitoring.png'} 
          alt="Worker Monitoring System"
        />
        <div className="product-info">
          <h3>AI-powered Worker Monitoring System</h3>
          <p>
            Our advanced monitoring dashboard allows employers to track worker performance, 
            productivity, and timelines in real-time. With interactive graphs, alerts, and 
            insights, you can stay informed and make data-driven decisions to improve workforce efficiency.
          </p>
        </div>
      </div>

      {/* Product 2: Employee Training Platform */}
      <div className="product">
        <img 
          src={process.env.PUBLIC_URL + '/images/employee-training.png'} 
          alt="AI-driven Employee Training"
        />
        <div className="product-info">
          <h3>AI-driven Employee Training Platform</h3>
          <p>
            Empower your employees with personalized, AI-powered training programs. Our platform provides 
            tailored learning paths, quizzes, and progress tracking to ensure continuous growth and skill enhancement.
          </p>
        </div>
      </div>
    </div>
  );
}
