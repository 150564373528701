// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import EmployerSignup from './components/EmployerSignup';
import WorkerSignup from './components/WorkerSignup';
import Login from './pages/Login';
import Products from './pages/Products';  // Import the Products page

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/employer-signup" element={<EmployerSignup />} />
        <Route path="/worker-signup" element={<WorkerSignup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/products" element={<Products />} /> {/* Add Products route */}
      </Routes>
    </Router>
  );
}

export default App;
