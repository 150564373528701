// src/pages/Login.js
import { useState } from 'react';
import '../App.css';  // Import your CSS

export default function Login() {
  const [userType, setUserType] = useState('Worker');

  const handleChange = (e) => {
    setUserType(e.target.value);
  };

  const handleLogin = () => {
    console.log(`Logging in as: ${userType}`);
    // Handle login logic here
  };

  return (
    <div className="login-page">
      <h2>Login</h2>

      <select value={userType} onChange={handleChange}>
        <option value="Worker">Worker</option>
        <option value="Employer">Employer</option>
      </select>

      <button onClick={handleLogin}>Login</button>
    </div>
  );
}
