// src/components/WorkerSignup.js
import { useState } from 'react';
import '../App.css';

export default function WorkerSignup() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    workCategory: '',
    experience: '',
    isExperienced: null, // Track if the user is fresher or experienced
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Log form data to console or handle it as needed
  };

  const handleExperienceSelection = (isExperienced) => {
    setFormData({ ...formData, isExperienced, experience: '' }); // Reset experience when switching
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Worker Registration</h2>

      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
      />

      <input
        type="text"
        name="phone"
        placeholder="Phone"
        value={formData.phone}
        onChange={handleChange}
      />

      <select
        name="workCategory"
        value={formData.workCategory}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Work Category</option>
        <option value="Hotel and Restaurant">Hotel and Restaurant</option>
        <option value="Retail Staff">Retail Staff</option>
        <option value="Warehouse Staff">Warehouse Staff</option>
        <option value="Delivery">Delivery</option>
        <option value="Field Sales">Field Sales</option>
        <option value="BPO">BPO</option>
        <option value="Construction Worker">Construction Worker</option>
        <option value="House Help">House Help</option>
        <option value="Hospital Staff">Hospital Staff</option>
        <option value="Factory Worker">Factory Worker</option>
        <option value="Security Staff">Security Staff</option>
      </select>

      {/* Experience Selection Buttons */}
      <div className="experience-buttons">
        <button
          type="button"
          className={formData.isExperienced === false ? 'active' : ''}
          onClick={() => handleExperienceSelection(false)}
        >
          Fresher
        </button>

        <button
          type="button"
          className={formData.isExperienced === true ? 'active' : ''}
          onClick={() => handleExperienceSelection(true)}
        >
          Experienced
        </button>
      </div>

      {/* Show Experience Input Only if Experienced is Selected */}
      {formData.isExperienced && (
        <input
          type="number"
          name="experience"
          placeholder="Years of Experience"
          value={formData.experience}
          onChange={handleChange}
          min="1"
        />
      )}

      <button type="submit">Register</button>
    </form>
  );
}
