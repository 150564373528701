// src/components/EmployerSignup.js
import { useState } from 'react';
import '../App.css';

export default function EmployerSignup() {
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    phone: '',
    industry: '',
    requirements: '',
    workersRequired: '', // New field for number of workers required
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // You can handle the form submission here
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Employer Registration</h2>

      <input
        type="text"
        name="companyName"
        placeholder="Company Name"
        value={formData.companyName}
        onChange={handleChange}
        required
      />

      <input
        type="text"
        name="contactPerson"
        placeholder="Contact Person"
        value={formData.contactPerson}
        onChange={handleChange}
        required
      />

      <input
        type="text"
        name="phone"
        placeholder="Phone"
        value={formData.phone}
        onChange={handleChange}
        required
      />

      <select
        name="industry"
        value={formData.industry}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select Industry</option>
        <option value="Construction">Construction</option>
        <option value="Retail">Retail</option>
        <option value="Hospitality">Hospitality</option>
        <option value="Manufacturing">Manufacturing</option>
        <option value="Healthcare">Healthcare</option>
      </select>

      {/* New input field for number of workers required */}
      <input
        type="number"
        name="workersRequired"
        placeholder="Number of Workers Required"
        value={formData.workersRequired}
        onChange={handleChange}
        min="1"
        required
      />

      <textarea
        name="requirements"
        placeholder="Enter Job Requirements"
        value={formData.requirements}
        onChange={handleChange}
        rows="4"
        required
      />

      <button type="submit">Register</button>
    </form>
  );
}
